import api from '@/service/api'

export default{
    async findDictionariesItemList(code){
        let data = [];
        // findDictionariesEnableItemList
        await api.platformApi.findDictionariesEnableItemList({code}).then(res => {
            res = res.data;
            if(res.code == 200){
                let arr = res.data;
                for(let i in arr){
                    let arrData = arr[i]
                    data[arrData.datavalue] = arrData
                }
            }
        })
        return data;
    }
}
